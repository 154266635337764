// 事件报表
<template>
  <div class="main noScroll">
    <div style=""
         class="topbar">
      <Tabs v-model="currentParentTabIndex"
            class="tabs"
            key="parent1"
            @on-click="onClickTab">
        <TabPane v-for="item in parentTabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <template v-if="currentParentTabIndex == 0 || currentParentTabIndex == 1">
        <Tabs v-model="currentTabIndex"
              class="tabs"
              key="parent2"
              @on-click="onClickTowTab">
          <TabPane v-for="item in tabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template>
      <template v-if="currentParentTabIndex == 2">
        <Tabs v-model="currentEventTabIndex"
              class="tabs"
              key="parent3"
              @on-click="onClickEventTab">
          <TabPane v-for="item in tabEventArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
      </template>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="selectType"
                    placeholder="查询分类"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in selectTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <template>
              <template v-if="currentParentTabIndex===0">
                <!-- <Select v-model="departmentType"
                        placeholder="机构类型"
                        class="m-r-10"
                        style="width:160px">
                  <Option v-for="item in departmentTypeArr"
                          :value="item.name"
                          :key="item.id">{{ item.name }}</Option>
                </Select> -->
                <MyTreeSelect :value="departmentName"
                              placeholder="所属机构"
                              clearable
                              :data="departmentTreeArr"
                              class="m-r-10"
                              style="width:160px"
                              @onSelectChange="onChangeDepartment">
                </MyTreeSelect>
              </template>
              <template v-if="currentParentTabIndex===1">
                <MyTreeSelect :value="departmentName"
                              placeholder="所属机构"
                              clearable
                              :data="departmentTreeArr"
                              class="m-r-10"
                              style="width:160px"
                              @onSelectChange="onChangeDepartment">
                </MyTreeSelect>
                <Select v-model="remoteName"
                        filterable
                        clearable
                        :remote-method="remoteNameMethod"
                        :loading="remoteNameLoading"
                        placeholder="请输入责任人"
                        class="m-r-10"
                        style="width:160px"
                        @on-query-change="onQueryChangeRemoteName">
                  <Option v-for="(option, index) in remoteNameList"
                          :value="option.uid"
                          :key="option.id">{{option.name}}</Option>
                </Select>
                <!-- <MyTreeSelect :value="person"
                              placeholder="责任人"
                              clearable
                              :data="personTreeArr"
                              search
                              class="m-r-10"
                              style="width:160px"
                              @onSelectChange="onChangePerson">
                </MyTreeSelect> -->
              </template>
            </template>
            <template v-if="currentParentTabIndex===2">
              <template v-if="currentEventTabIndex==0">

                <MyTreeSelect :value="departmentName"
                              placeholder="所属机构"
                              clearable
                              :data="departmentTreeArr"
                              class="m-r-10"
                              style="width:160px"
                              @onSelectChange="onChangeDepartment">
                </MyTreeSelect>
                <!-- <MyTreeSelect :value="account"
                              placeholder="上报人"
                              readonly
                              clearable
                              :data="accountArr"
                              search
                              @onSelectChange="onChangeAccount"
                              style="width:160px;margin-right: 10px;">
                </MyTreeSelect> -->
                <Select v-model="remoteAccount"
                        filterable
                        clearable
                        :remote-method="remoteAccountMethod"
                        :loading="remoteAccountLoading"
                        placeholder="请输入上报人"
                        class="m-r-10"
                        style="width:160px"
                        @on-query-change="onQueryChangeRemoteAccount">
                  <Option v-for="(option, index) in remoteAccountList"
                          :value="option.uid"
                          :key="option.id">{{option.name}}</Option>
                </Select>
              </template>
              <template v-if="currentEventTabIndex==1">
                <Select v-model="questBigType"
                        placeholder="问题大类"
                        class="m-r-10"
                        style="width:160px">
                  <Option v-for="item in questBigTypeArr"
                          :value="item.id"
                          :key="item.id">{{ item.name }}</Option>
                </Select>
                <Select v-model="questSmallType"
                        placeholder="问题小类"
                        :disabled="!questBigType"
                        class="m-r-10"
                        style="width:160px">
                  <Option v-for="item in questSmallTypeArr"
                          :value="item.id"
                          :key="item.id">{{ item.name }}</Option>
                </Select>
              </template>
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        ref="formDate"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        :options="dateOptions"
                        @on-change="onChangeDate"
                        style="width:260px"
                        v-show="selectType===1"></DatePicker>
            <!-- <DatePicker :value="dateMonth"
                        type="month"
                        placeholder="选择月份"
                        :options="dateOptions"
                        @on-change="onChangeMonth"
                        style="width:160px"
                        v-show="selectType===2 && currentParentTabIndex == 2"></DatePicker> -->
            <Monthrange :itemStyle="{width:'160px','margin-right':'10px'}"
                        :dateMonthrange="dateMonthrange"
                        @onChange="onChangeMonthrange"
                        v-show="selectType===2"></Monthrange>
          </div>
          <div class="flex">
            <template v-if="selectType===1">
              <!-- <Button type="primary"
                      class="m-l-10"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickToday"
                      v-if="currentParentTabIndex == 2">今天</Button>
              <Button type="primary"
                      class="m-l-10"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickYesterday"
                      v-if="currentParentTabIndex == 2">昨天</Button> -->
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/eventReport/query'])"
                      :ghost="buttonIndex == 2 ? false : true"
                      @click.stop="onClickNearlyThreeDay">近三天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/eventReport/query'])"
                      :ghost="buttonIndex == 7 ? false : true"
                      @click.stop="onClickNearlySevenDay">近七天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/eventReport/query'])"
                      :ghost="buttonIndex == 3 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
            </template>
            <template v-if="selectType===2">
              <!-- <Button type="primary"
                      class="m-l-10"
                      :ghost="buttonIndex == 3 ? false : true"
                      @click.stop="onClickCurrentMonth"
                      v-if="currentParentTabIndex == 2">本月</Button>
              <Button type="primary"
                      class="m-l-10"
                      :ghost="buttonIndex == 4 ? false : true"
                      @click.stop="onClickLastMonth"
                      v-if="currentParentTabIndex == 2">上月</Button> -->
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/eventReport/query'])"
                      :ghost="buttonIndex == 5 ? false : true"
                      @click.stop="onClickPreMonthDay(3)">近三月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/eventReport/query'])"
                      :ghost="buttonIndex == 6 ? false : true"
                      @click.stop="onClickPreMonthDay(6)">近六月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/eventReport/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/eventReport/query'])"
                    @click.stop="onResetSearchData">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- <template v-if="currentTabIndex===0">
        <div class="section">
          <Tabs v-model="currentEventEchartsTabIndex"
                class="tabs"
                key="childEcharts1"
                @on-click="onClickTabs">
            <TabPane v-for="item in eventEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="getEchartsData"></Echarts>
        </div>
      </template> -->
      <template v-if="currentParentTabIndex != 2">
        <template v-if="currentTabIndex===0||currentTabIndex===1">
          <div class="section">
            <Tabs v-model="currentDisposeEchartsTabIndex"
                  class="tabs"
                  key="childEcharts2">
              <TabPane v-for="item in disposeEchartsTabArr"
                       :key="item.name"
                       :label="item.name"></TabPane>
            </Tabs>
            <Echarts height="400px"
                     :options="totalOptions"
                     :data="getEchartsData"
                     v-show="getEchartsData.length"></Echarts>
            <div class="noData"
                 v-show="getEchartsData.length===0">
              <img src="@/assets/common/tubiaono.png">
              暂无数据
            </div>
          </div>
        </template>
      </template>
      <div class="section">
        <!-- <template v-if="currentTabIndex===0">
          <Tabs v-model="currentEventTabIndex"
                class="tabs"
                key="child1"
                @on-click="onClickTabList">
            <TabPane v-for="item in eventTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
        </template> -->
        <div class="flex a-center j-between p-t-10">
          <div></div>
          <Dropdown v-if="checkPermi(['/admin/eventReport/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getData"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import MyTreeSelect from '@/components/common/MyTreeSelect.vue'
import { mapActions, mapGetters } from 'vuex';
import { getToday, getYesterday, getNearlyThreeDay, getCurrentMonth, getLastMonth, getYearOrMonthForMonth, getCurrentYearToNow, getNearlySevenDay, getPreMonthDay } from '@/utils/dateRangUtil'
import Monthrange from '@/components/common/Monthrange'
import ExportTemplate from '@/components/common/ExportTemplate'
import { throttle } from '@/utils/debounceAndThrottle';
import { checkPermi } from '@/utils/permission'
export default {
  components: { Echarts, MyTreeSelect, Monthrange, ExportTemplate },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      deptProblemEchartsData: [], // 机构问题echarts
      deptTimeEcharsData: [], // 机构时间echarts
      deptSituationTotalEventsEcharsData: [], //机构处理情况总事件处理情况
      deptSituationAnalysisEcharsData: [], //机构处理分析总事件处理情况
      personProblemEchartsData: [], //责任人事件分布问题echarts
      PersonTimeEcharsData: [], //责任人事件分布时间echarts
      personSituationTotalEventsEcharsData: [], //责任人处理情况总事件处理情况
      personSituationAnalysisEcharsData: [], //责任人处理分析总事件处理情况
      currentParentTabIndex: 0,
      buttonIndex: null,
      parentTabArr: [
        {
          name: '按责任机构统计'
        },
        {
          name: '按责任人统计'
        },
        {
          name: '按事件分布统计'
        },
      ],
      currentTabIndex: 0,
      tabArr: [
        // {
        //   name: '事件分布'
        // },
        {
          name: '处理情况'
        },
        {
          name: '处理及时分析'
        },
      ],
      currentEventTabIndex: 0,
      tabEventArr: [
        {
          name: '按来源统计'
        },
        {
          name: '按问题类型统计'
        },
      ],
      departmentName: '',
      departmentId: '',
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      departmentType: '',
      departmentTypeArr: [
        {
          id: 1,
          name: '全部'
        },
        {
          id: 2,
          name: '项目'
        },
        {
          id: 3,
          name: '标段'
        },
        {
          id: 4,
          name: '部门'
        },
        {
          id: 5,
          name: '班组'
        },
      ],
      account: '',
      remoteAccount: '',
      remoteAccountLoading: false,
      remoteAccountList: [],
      questBigType: '',
      questSmallType: '',
      questSmallTypeArr: [],
      person: '',
      personId: '',
      remoteName: '',
      remoteNameLoading: false,
      remoteNameList: [],
      date: [],
      dateMonth: '',
      dateMonthrange: [],
      currentEventEchartsTabIndex: 0,
      eventEchartsTabArr: [
        {
          name: '按问题类型'
        },
        {
          name: '时间走势'
        },
      ],
      currentEventTabIndex: 0,
      eventTabArr: [
        {
          name: '按来源统计'
        },
        {
          name: '按问题类型'
        },
      ],
      currentDisposeEchartsTabIndex: 0,
      disposeEchartsTabArr: [
        {
          name: '总事件处理情况'
        },
      ],
      totalOptions: {
        dataset: {
          source: []
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          name: '单位：件',
          minInterval: 1,
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderWidth: '1',
          borderColor: 'gray',
          textStyle: {
            color: 'black'
          },
          formatter: '',
        },
        series: [{
          type: 'line',
          // smooth: true,
          barWidth: 30,
        }],
        color: ['#25bb96'],
      },
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      deptEventParams: {},
      dataDepartmentEventSource: [],
      columnsDepartmentEventSource: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            if (!row.depName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.depName)
          }
        },
        {
          title: '机构类型',
          key: 'depType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '机构主管',
          key: 'executiveDirector',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计事件(件)',
          key: 'casesCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataDepartmentEventQuestType: [],
      columnsDepartmentEventQuestType: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (!row.depName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.depName)
          }
        },
        {
          title: '机构类型',
          key: 'depType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '机构主管',
          key: 'executiveDirector',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计事件(件)',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '道路不洁(件)',
          key: 'roadUncleanCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '暴露垃圾(件)',
          key: 'exposedGarbageCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '垃圾污染(件)',
          key: 'garbagePollutionCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '其它(件)',
          key: 'otherCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataDepartmentDispose: [],
      columnsDepartmentDispose: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            if (!row.depName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.depName)
          }
        },
        {
          title: '机构类型',
          key: 'depType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '机构主管',
          key: 'executiveDirector',
          tooltip: true,
          align: 'center'
        },
        {
          title: '应处理事件(件)',
          key: 'eventsShouldHandledCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际处理事件(件)',
          key: 'eventsActualHandledCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际处理率',
          key: 'eventsActualHandledRate',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.eventsActualHandledRate + '%')
          }
        },
        {
          title: '未结案(件)',
          key: 'openCaseCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataDepartmentAnalyse: [],
      columnsDepartmentAnalyse: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            if (!row.depName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.depName)
          }
        },
        {
          title: '机构类型',
          key: 'depType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '机构主管',
          key: 'executiveDirector',
          tooltip: true,
          align: 'center'
        },
        {
          title: '应处理事件(件)',
          key: 'eventsShouldHandledCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际处理事件(件)',
          key: 'eventsActualHandledCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '及时处理事件(件)',
          key: 'timelyAnalysisCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '及时处理率',
          key: 'timelyAnalysisRate',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.timelyAnalysisRate + '%')
          }
        },
        {
          title: '逾期处理事件(件)',
          key: 'unTimelyAnalysisCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '逾期处理率',
          key: 'unTimelyAnalysisRate',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.unTimelyAnalysisRate + '%')
          }
        },
      ],
      dataPersonEventSource: [],
      columnsPersonEventSource: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'userName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            if (!row.userName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.userName)
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '是否主管',
        //   key: 'isSupervisor',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '合计事件(件)',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataPersonEventQuestType: [],
      columnsPersonEventQuestType: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'userName',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (!row.userName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.userName)
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '是否主管',
        //   key: 'isSupervisor',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '合计事件(件)',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '道路不洁(件)',
          key: 'dlbjCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '暴露垃圾(件)',
          key: 'blljCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '垃圾污染(件)',
          key: 'ljwrCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '其它(件)',
          key: 'qitaCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataPersonDispose: [],
      columnsPersonDispose: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'userName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            if (!row.userName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.userName)
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '是否主管',
        //   key: 'isSupervisor',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '应处理事件(件)',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际处理事件(件)',
          key: 'dlbjCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际处理率',
          key: 'processingRate',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.processingRate + '%')
          }
        },
        {
          title: '未结案(件)',
          key: 'qitaCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataPersonAnalyse: [],
      columnsPersonAnalyse: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'userName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            if (!row.userName) {
              return h('span', {
                style: {
                  color: '#dcdee2',
                }
              }, '未指派')
            }
            return h('span', row.userName)
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '是否主管',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '应处理事件(件)',
          key: 'eventsShouldHandledCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际处理事件(件)',
          key: 'eventsActualHandledCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '及时处理事件(件)',
          key: 'timelyAnalysisCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '及时处理率',
          key: 'timelyAnalysisRate',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.timelyAnalysisRate + '%')
          }
        },
        {
          title: '逾期处理事件(件)',
          key: 'unTimelyAnalysisCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '逾期处理率',
          key: 'unTimelyAnalysisRate',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.unTimelyAnalysisRate + '%')
          }
        },
      ],
      // 事件分布 来源统计
      dataEventSource: [],
      columnsEventSource: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'createDeptName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '上报人',
          key: 'createName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计事件(件)',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '已结案数量(件)',
          key: 'closedCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未结案数量(件)',
          key: 'openCount',
          tooltip: true,
          align: 'center'
        },
      ],
      // 事件分布 问题类型统计
      dataEventQuestType: [],
      columnsEventQuestType: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'createDeptName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '上报人',
          key: 'createName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计事件(件)',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '道路不洁(件)',
          key: 'dlbjCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '暴露垃圾(件)',
          key: 'blljCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '垃圾污染(件)',
          key: 'ljwrCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '其它(件)',
          key: 'qitaCount',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      valve: false,
      todayArr: ''
    };
  },
  watch: {
    questBigType (newVal) {
      let arr = []
      if (newVal) {
        let obj = this.questBigTypeArr.find(item => item.id === newVal)
        arr = obj ? obj.children : []
      }
      this.questSmallType = ''
      this.questSmallTypeArr = arr
    },
    selectType (newVal) {
      // if (this.currentParentTabIndex != 2) {
      if (newVal == 1) {
        this.onClickCurrentMonth()
      } else {
        this.onClickCurrentYear()
      }
      // }
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentParentTabIndex===0) {
            if (this.currentTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.executiveDirector == items.executiveDirector
              })
              this.selectIndexArr.push(data.executiveDirector)
            }
            if (this.currentTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.executiveDirector == items.executiveDirector
              })
              this.selectIndexArr.push(data.executiveDirector)
            }

          } if (this.currentParentTabIndex===1) {
            if (this.currentTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.userName == items.userName
              })
              this.selectIndexArr.push(data.userName)
            }
            if (this.currentTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.userName == items.userName
              })
              this.selectIndexArr.push(data.userName)
            }

          } if (this.currentParentTabIndex===2) {
            if (this.currentEventTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.createName == items.createName
              })
              this.selectIndexArr.push(data.createName)
            }
            if (this.currentEventTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.createName == items.createName
              })
              this.selectIndexArr.push(data.createName)
            }

          }
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'personTreeArr': 'getUserTreeList',
      'accountArr': 'getUserTreeList'
    }),
    getColumns () {
      let columns = []
      switch (this.currentParentTabIndex) {
        case 0:
          switch (this.currentTabIndex) {
            // case 0:
            //   switch (this.currentEventTabIndex) {
            //     case 0:
            //       columns = this.columnsDepartmentEventSource
            //       break;
            //     case 1:
            //       columns = this.columnsDepartmentEventQuestType
            //       break;
            //     default:
            //       break;
            //   }
            //   break;
            case 0:
              columns = this.columnsDepartmentDispose
              break;
            case 1:
              columns = this.columnsDepartmentAnalyse
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentTabIndex) {
            // case 0:
            //   switch (this.currentEventTabIndex) {
            //     case 0:
            //       columns = this.columnsPersonEventSource
            //       break;
            //     case 1:
            //       columns = this.columnsPersonEventQuestType
            //       break;
            //     default:
            //       break;
            //   }
            //   break;
            case 0:
              columns = this.columnsPersonDispose
              break;
            case 1:
              columns = this.columnsPersonAnalyse
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentEventTabIndex) {
            case 0:
              columns = this.columnsEventSource
              break;
            case 1:
              columns = this.columnsEventQuestType
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let columns = []
      switch (this.currentParentTabIndex) {
        case 0:
          switch (this.currentTabIndex) {
            // case 0:
            //   switch (this.currentEventTabIndex) {
            //     case 0:
            //       columns = this.dataDepartmentEventSource
            //       break;
            //     case 1:
            //       columns = this.dataDepartmentEventQuestType
            //       break;
            //     default:
            //       break;
            //   }
            //   break;
            case 0:
              columns = this.dataDepartmentDispose
              break;
            case 1:
              columns = this.dataDepartmentAnalyse
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentTabIndex) {
            // case 0:
            //   switch (this.currentEventTabIndex) {
            //     case 0:
            //       columns = this.dataPersonEventSource
            //       break;
            //     case 1:
            //       columns = this.dataPersonEventQuestType
            //       break;
            //     default:
            //       break;
            //   }
            //   break;
            case 0:
              columns = this.dataPersonDispose
              break;
            case 1:
              columns = this.dataPersonAnalyse
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentEventTabIndex) {
            case 0:
              columns = this.dataEventSource
              break;
            case 1:
              columns = this.dataEventQuestType
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getEchartsData () {
      let columns = []
      switch (this.currentParentTabIndex) {
        case 0:
          switch (this.currentTabIndex) {
            // case 0:
            //   switch (this.currentEventEchartsTabIndex) {
            //     case 0:
            //       columns = this.deptProblemEchartsData
            //       break;
            //     case 1:
            //       columns = this.deptTimeEcharsData
            //       break;
            //     default:
            //       break;
            //   }
            //   break;
            case 0:
              columns = this.deptSituationTotalEventsEcharsData
              break;
            case 1:
              columns = this.deptSituationAnalysisEcharsData
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentTabIndex) {
            // case 0:
            //   switch (this.currentEventEchartsTabIndex) {
            //     case 0:
            //       columns = this.personProblemEchartsData
            //       break;
            //     case 1:
            //       columns = this.PersonTimeEcharsData
            //       break;
            //     default:
            //       break;
            //   }
            //   break;
            case 0:
              columns = this.personSituationTotalEventsEcharsData
              break;
            case 1:
              columns = this.personSituationAnalysisEcharsData
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    // 问题大类
    questBigTypeArr () {
      let arr = [
        // {
        //   id: 0,
        //   name: '全部'
        // }
      ]
      return arr.concat(this.$store.getters.getWorkOrderQuestTypeTreeList)
    },
    getExportPath () {
      let path = ''
      switch (this.currentParentTabIndex) {
        case 0:
          switch (this.currentTabIndex) {
            case 0:
              path = this.$http.exportDeptSituation
              break;
            case 1:
              path = this.$http.exportDeptSituationAnalysis
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentTabIndex) {
            case 0:
              path = this.$http.exportPersonSituation
              break;
            case 1:
              path = this.$http.exportPersonSituationAnalysis
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentEventTabIndex) {
            case 0:
              path = this.$http.exportCaseSituation
              break;
            case 1:
              path = this.$http.exportCaseSituationAnalysis
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList',
      'updateUserTreeList',
      'updateWorkOrderLevelAllList',
      'updateWorkOrderQuestTypeTreeList',
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
      this.updateUserTreeList()
      this.updateWorkOrderLevelAllList()
      this.updateWorkOrderQuestTypeTreeList()
      this.todayArr = getToday().split('-')
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 责任机构事件分布
    getDeptEventData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        depId: this.departmentId,   // 机构ID 
        userId: this.remoteAccount,   // 上报人ID
        problemCategoryId: this.questBigType == 0 ? '' : this.questBigType, // 问题大类 
        problemSubclassId: this.questSmallType, // 问题小类 
        depType: this.departmentType == '全部' ? '' : this.departmentType,    // 机构类型
      }
      this.dateJudge(params)
      this.getDeptProblemEchars(params)
      this.getDeptTimeEchars(params)
      if (this.valve) {
        if (this.currentEventTabIndex == 0) {
          this.getDeptSourceList(params)
        } else {
          this.getDeptProblemList(params)
        }
      } else {
        this.getDeptSourceList(params)
        this.getDeptProblemList(params)
      }
      this.valve = false
    },
    // 机构事件分布问题 eachers 数据
    getDeptProblemEchars (params) {
      this.$http.getDeptProblemEchars(params).then(res => {
        if (res.code == 200) {
          this.totalOptions.tooltip.formatter = ''
          this.totalOptions.series[0].type = 'bar'
          this.deptProblemEchartsData = res.result
        }
      })
    },
    // 机构事件分布时间 eachers 数据
    getDeptTimeEchars (params) {
      this.$http.getDeptTimeEchars(params).then(res => {
        if (res.code == 200) {
          this.totalOptions.tooltip.formatter = ''
          this.totalOptions.series[0].type = 'line'
          this.deptTimeEcharsData = res.result
        }
      })
    },
    // 机构事件分布事件来源列表
    getDeptSourceList (params) {
      this.listData('getDeptSourceList', params, 'dataDepartmentEventSource',)
    },
    // 机构事件分布问题类型列表
    getDeptProblemList (params) {
      this.listData('getDeptProblemList', params, 'dataDepartmentEventQuestType',)
    },
    // 机构处理情况
    getDeptSituationData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        depId: this.departmentId,   // 机构ID 
        depType: this.departmentType == '全部' ? '' : this.departmentType,    // 机构类型
      }
      this.dateJudge(params) // 时间
      this.getDeptSituationTotalEventsEchars(params)
      this.getDeptSituationList(params)
    },
    // 机构处理情况 总事件处理情况 eachars
    getDeptSituationTotalEventsEchars (params) {
      params.dateType = this.selectType
      this.$http.getDeptSituationTotalEventsEchars(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                ${e[0].data[0]}<br />
                全部: ${e[0].data[1]}<br />
                <span style="color:#ff1a1a">待分派: </span> ${e[0].data[2]}<br />
                <span style="color:#fe9400">待处理: </span>${e[0].data[3]}<br />
                <span style="color:#0079fe">待结案: </span>${e[0].data[4]}<br />
                <span style="color:#007936">已结案: </span>${e[0].data[5]}<br />
                <span style="color:#8b8e86">废弃工单: </span>${e[0].data[6]}<br />
              </div>
            `
          }
          this.totalOptions.series[0].type = 'line'
          this.deptSituationTotalEventsEcharsData = data
        }
      })
    },
    // 机构处理情况列表数据
    getDeptSituationList (params) {
      delete params.dateType
      this.listData('getDeptSituationList', params, 'dataDepartmentDispose')
    },
    // 机构处理及时分析
    getDeptSituationAnalysisData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        depId: this.departmentId,   // 机构ID 
        depType: this.departmentType == '全部' ? '' : this.departmentType,    // 机构类型
      }
      this.dateJudge(params) // 时间
      this.getDeptSituationAnalysisTotalEventsEchars(params)
      this.getDeptSituationAnalysisList(params)
    },
    // 机构处理及时分析 总事件处理情况 eachars
    getDeptSituationAnalysisTotalEventsEchars (params) {
      params.dateType = this.selectType
      this.$http.getDeptSituationAnalysisEchars(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
                ${e[0].data[0]}<br />
                <span style="color:#c66f35">全部: </span> ${e[0].data[1]}<br />
                <span style="color:#c66f35">逾期: </span> ${e[0].data[2]}<br />
                <span style="color:#8ace57">未逾期: </span> ${e[0].data[3]}<br />
            `
          }
          this.totalOptions.series[0].type = 'line'
          this.deptSituationAnalysisEcharsData = data
        }
      })
    },
    // 机构处理情况列表数据
    getDeptSituationAnalysisList (params) {
      delete params.dateType
      this.listData('getDeptSituationAnalysisList', params, 'dataDepartmentAnalyse')
    },
    // 责任人事件分布
    getPersonEventData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personId: this.remoteName,   // 责任人ID 
        createUid: this.remoteAccount,   // 上报人ID
        caseIssueId: this.questBigType == 0 ? '' : this.questBigType, // 问题大类 
        caseIssueChildId: this.questSmallType, // 问题小类 
        depId: this.departmentId
      }
      this.dateJudge(params)
      this.getPersonProblemEchars(params)
      this.getPersonTimeEchars(params)
      if (this.valve) {
        if (this.currentEventTabIndex == 0) {
          this.getPersonSourceList(params)
        } else {
          this.getPersonProblemList(params)
        }
      } else {
        this.getPersonSourceList(params)
        this.getPersonProblemList(params)
      }
      this.valve = false
    },
    // 责任人事件分布问题 eachers 数据
    getPersonProblemEchars (params) {
      this.$http.getPersonProblemEchars(params).then(res => {
        if (res.code == 200) {
          this.totalOptions.tooltip.formatter = ''
          this.totalOptions.series[0].type = 'bar'
          this.personProblemEchartsData = res.result
        }
      })
    },
    // 责任人事件分布时间 eachers 数据
    getPersonTimeEchars (params) {
      this.$http.getPersonTimeEchars(params).then(res => {
        if (res.code == 200) {
          this.totalOptions.tooltip.formatter = ''
          this.totalOptions.series[0].type = 'line'
          this.PersonTimeEcharsData = res.result
        }
      })
    },
    // 责任人事件分布事件来源列表
    getPersonSourceList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personId: this.remoteName,   // 责任人ID 
        createUid: this.remoteAccount,   // 上报人ID
        caseIssueId: this.questBigType == 0 ? '' : this.questBigType, // 问题大类 
        caseIssueChildId: this.questSmallType, // 问题小类 
        depId: this.departmentId,   // 机构ID 
      }
      this.dateJudge(params)
      this.listData('getPersonSourceList', params, 'dataEventSource',)
    },
    // 责任人事件分布问题类型列表
    getPersonProblemList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personId: this.remoteName,   // 责任人ID 
        createUid: this.remoteAccount,   // 上报人ID
        caseIssueId: this.questBigType == 0 ? '' : this.questBigType, // 问题大类 
        caseIssueChildId: this.questSmallType, // 问题小类 
        depId: this.departmentId,   // 机构ID 
      }
      this.dateJudge(params)
      this.listData('getPersonProblemList', params, 'dataEventQuestType',)
    },
    // 责任人处理情况
    getPersonSituationData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personId: this.remoteName,   // 责任人ID 
        depId: this.departmentId,   // 机构ID 
      }
      this.dateJudge(params) // 时间
      this.getPersonSituationTotalEventsEchars(params)
      this.getPersonSituationList(params)
    },
    // 责任人处理情况 总事件处理情况 eachars
    getPersonSituationTotalEventsEchars (params) {
      params.dateType = this.selectType
      this.$http.getPersonSituationTotalEventsEchars(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                全部: ${e[0].data[1]}<br />
                <span style="color:#ff1a1a">待分派: </span> ${e[0].data[2]}<br />
                <span style="color:#fe9400">待处理: </span>${e[0].data[3]}<br />
                <span style="color:#0079fe">待结案: </span>${e[0].data[4]}<br />
                <span style="color:#007936">已结案: </span>${e[0].data[5]}<br />
                <span style="color:#8b8e86">废弃工单: </span>${e[0].data[6]}<br />
              </div>
            `
          }
          this.totalOptions.series[0].type = 'line'
          this.personSituationTotalEventsEcharsData = data
        }
      })
    },
    // 责任人处理情况列表数据
    getPersonSituationList (params) {
      delete params.dateType
      this.listData('getPersonSituationList', params, 'dataPersonDispose')
    },
    // 责任人处理及时分析
    getPersonSituationAnalysisData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personId: this.remoteName,   // 责任人ID 
        depId: this.departmentId
      }
      this.dateJudge(params) // 时间
      this.getPersonSituationAnalysisList(params)
      this.getPersonSituationAnalysisTotalEventsEchars(params)
    },
    // 责任人处理及时分析 总事件处理情况 eachars
    getPersonSituationAnalysisTotalEventsEchars (params) {
      params.dateType = this.selectType
      this.$http.getPersonSituationAnalysisEchars(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
                ${e[0].data[0]}<br />
                <span style="color:#c66f35">全部: </span> ${e[0].data[1]}<br />
                <span style="color:#c66f35">逾期: </span> ${e[0].data[2]}<br />
                <span style="color:#8ace57">未逾期: </span> ${e[0].data[3]}<br />
            `
          }
          this.totalOptions.series[0].type = 'line'
          this.personSituationAnalysisEcharsData = data
        }
      })
    },
    // 责任人处理情况列表数据
    getPersonSituationAnalysisList (params) {
      delete params.dateType
      this.listData('getPersonSituationAnalysisList', params, 'dataPersonAnalyse')
    },
    // 列表
    async listData (api, params, data) {
      this.tableLoading = true
      let res = {}
      try {
        res = await this.$http[api](params)
        if (res.code == 200) {
          this.tableLoading = false
          this[data] = res.result.rows
          this.totalPage = res.result.total
        }
      } catch (err) {
        this.tableLoading = false
      }
      return res
    },
    // 按天or按月
    dateJudge (params) {
      if (this.date[0]) {
        params.startDate = this.date[0]
        params.endDate = this.date[1]
      }
      return params
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 修改责任人
    onChangePerson (data) {
      if (data.type !== 'depart') {
        this.personId = data.id
        this.person = data.name
      }
    },
    onChangeDate (date) {
      // if (this.currentParentTabIndex == 2) {
      //   this.date = date
      // } else {
      let millisecond = 1000 * 60 * 60 * 24
      let startDate = new Date(date[0]).getTime()
      let endDate = new Date(date[1]).getTime()
      if (endDate - startDate < millisecond * 2) {
        this.date = getCurrentMonth()
        this.$Message.info('最少选择三天')
      } else {
        this.date = date
      }
      // }
      this.buttonIndex = null
    },
    onChangeMonth (date) {
      this.dateMonth = date
      let arr = this.dateMonth.split('-')
      let month = getYearOrMonthForMonth(arr[0], arr[1])
      this.date = month
      this.buttonIndex = null
    },
    onChangeMonthrange (arr) {
      let startMonthArr = arr.month[0].split('-')
      let endMonthArr = arr.month[1].split('-')
      if (parseInt(endMonthArr[1]) - parseInt(startMonthArr[1]) >= 2) {
        this.date = arr.day
      } else {
        this.date = []
        this.dateMonthrange = []
        this.$Message.info('最少选择三个月')
      }
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {

      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.depName)
          break;
        case 1:
          this.selectArr = selection.map(item => item.personId)
          break;
        case 2:
          this.selectArr = selection.map(item => item.createUid)
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.currentParentTabIndex === 0) {
        if (this.currentTabIndex === 0) {
          if (this.selectIndexArr.includes(row.executiveDirector)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentTabIndex === 1) {
          if (this.selectIndexArr.includes(row.executiveDirector)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentParentTabIndex === 1 ){
        if (this.currentTabIndex === 0) {
          if (this.selectIndexArr.includes(row.userName)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentTabIndex === 1) {
          if (this.selectIndexArr.includes(row.userName)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentParentTabIndex === 2 ){
        if (this.currentEventTabIndex === 0) {
          if (this.selectIndexArr.includes(row.createName)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentEventTabIndex === 1) {
          if (this.selectIndexArr.includes(row.createName)) {
            return 'table-bgcolor'
          }
        }

      }
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay(false)
      this.getList()
      this.buttonIndex = 2
    },
    // 昨天
    onClickYesterday () {
      this.date = [getYesterday(), getYesterday()]
      this.getList()
      this.buttonIndex = 1
    },
    // 今天
    onClickToday () {
      this.date = [getToday(), getToday()]
      this.getList()
      this.buttonIndex = 0
    },
    // 近七天
    onClickNearlySevenDay () {
      this.date = getNearlySevenDay(false)
      this.getList()
      this.buttonIndex = 7
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth(false)
      let time = getCurrentMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.buttonIndex = 3
      let millisecond = 1000 * 60 * 60 * 24
      if (new Date(this.date[1]).getTime() - new Date(this.date[0]).getTime() < millisecond * 2) {
        this.$Message.info('最少选择三天')
        this.deptSituationTotalEventsEcharsData = []
        this.dataDepartmentDispose = []
        this.deptSituationAnalysisEcharsData = []
        this.dataDepartmentAnalyse = []
        this.personProblemEchartsData = []
        this.PersonTimeEcharsData = []
        this.dataEventSource = []
        this.dataEventQuestType = []
        this.personSituationTotalEventsEcharsData = []
        this.dataPersonDispose = []
        this.personSituationAnalysisEcharsData = []
        this.dataPersonAnalyse = []
        this.totalPage = 0
        return
      }
      this.getList()
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      let time = getLastMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.getList()
      this.buttonIndex = 4
    },
    // 近几个月
    onClickPreMonthDay (num) {
      this.date = getPreMonthDay(num)
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
      switch (num) {
        case 3:
          this.buttonIndex = 5
          break;
        case 6:
          this.buttonIndex = 6
          break;
        default:
          break;
      }
    },
    // 本年
    onClickCurrentYear () {
      this.date = getCurrentYearToNow()
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 用户账号
    onChangeAccount (section) {
      if (section.type != 'depart') {
        this.account = section.name
        this.accountId = section.id
      }
    },
    // 获取数据
    getList () {
      this.selectIndexArr = []
      switch (this.currentParentTabIndex) {
        case 0:
          switch (this.currentTabIndex) {
            case 0:
              this.getDeptSituationData()
              break;
            case 1:
              this.getDeptSituationAnalysisData()
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentTabIndex) {
            case 0:
              this.getPersonSituationData()
              break;
            case 1:
              this.getPersonSituationAnalysisData()
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentEventTabIndex) {
            case 0:
              this.getPersonSourceList()
              break;
            case 1:
              this.getPersonProblemList()
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // 一级tabs标签页切换
    onClickTab (data) {
      this.selectArr = []
      this.currentTabIndex = 0
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 二级tabs 标签页切换
    onClickTowTab (data) {
      this.selectArr = []
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 事件二级tabs切换
    onClickEventTab () {
      this.selectArr = []
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // echars图 tabs标签页切换
    onClickTabs (data) {
      this.resetSearchData()
      if (this.currentEventEchartsTabIndex == 0) {
        this.totalOptions.series[0].type = 'bar'
      } else {
        this.totalOptions.series[0].type = 'line'
      }

    },
    // tabs表格页切换
    onClickTabList (data) {
      // console.log(data);
    },
    // 责任人远程搜索
    remoteNameMethod: throttle(function (query) {
      if (query !== '') {
        this.remoteNameLoading = true;
        let params = {
          page: 1,
          size: 99999,
        }
        this.$http.getUserList(params).then((res) => {
          if (res.code === 200) {
            this.remoteNameLoading = false
            let data = res.result.users
            this.remoteNameList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteNameLoading = false
            this.remoteNameList = [];
          }
        })
      } else {
        this.remoteNameList = [];
      }
    }),
    onQueryChangeRemoteName (value) {
      if (!value) {
        this.remoteNameList = []
      }
    },
    // 上报人远程搜索
    remoteAccountMethod: throttle(function (query) {
      if (query !== '') {
        this.remoteAccountLoading = true;
        let params = {
          page: 1,
          size: 99999,
        }
        this.$http.getUserList(params).then((res) => {
          if (res.code === 200) {
            this.remoteAccountLoading = false
            let data = res.result.users
            this.remoteAccountList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteAccountLoading = false
            this.remoteAccountList = [];
          }
        })
      } else {
        this.remoteAccountList = [];
      }
    }),
    onQueryChangeRemoteAccount (value) {
      if (!value) {
        this.remoteAccountList = []
      }
    },
    // 查询
    onClickSearch () {
      this.valve = true
      this.pageNum = 1
      this.pageSize = 20
      this.getList()
    },
    // 重置按钮
    onResetSearchData () {
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 重置搜索条件
    resetSearchData () {
      this.pageNum = 1
      this.pageSize = 20
      this.selectType = 1
      this.departmentType = ''
      this.departmentName = ''
      this.departmentId = ''
      this.account = ''
      this.accountId = ''
      this.remoteAccount = ''
      this.person = ''
      this.personId = ''
      this.remoteName = ''
      this.questBigType = ''
      this.questSmallType = ''
      // this.date = []
      // this.dateMonth = ''
      // this.dateMonthrange = []
      if (this.$refs.formDate) {
        this.$refs.formDate.handleClear()
      }
      this.buttonIndex = null
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentParentTabIndex) {
        case 0:
          this.dateJudge(data) // 时间
          switch (value) {
            case "now":
              data.depNames = this.getData.map(item => {
                return item.depName
              })
              break;
            case "part":
              data.depNames = this.selectArr.map(item => {
                return item.depName
              })
              break;
            case "all":
              break;
            default:
              break;
          }
          break;
        case 1:
          this.dateJudge(data) // 时间
          switch (value) {
            case "now":
              data.personIds = this.getData.map(item => {
                return item.personId
              })
              break;
            case "part":
              data.personIds = this.selectArr.map(item => {
                return item.personId
              })
              break;
            case "all":
              break;
            default:
              break;
          }
          break;
        case 2:
          this.dateJudge(data) // 时间
          switch (this.currentEventTabIndex) {
            case 0:
              data.createUid = this.remoteAccount   // 上报人ID
              data.depId = this.departmentId   // 机构ID
              break;
            case 1:
              data.caseIssueId = this.questBigType == 0 ? '' : this.questBigType // 问题大类 
              data.caseIssueChildId = this.questSmallType // 问题小类 
              break;
            default:
              break;
          }
          switch (value) {
            case "now":
              data.createUids = this.getData.map(item => {
                return item.createUid
              })
              break;
            case "part":
              data.createUids = this.selectArr.map(item => {
                return item.createUid
              })
              break;
            case "all":
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.noData {
  height: 400px;
  font-size: 16px;
  img {
    width: 150px;
    height: 100px;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>